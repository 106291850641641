import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled, {createGlobalStyle} from 'styled-components';
import {Route, Switch} from 'react-router-dom';
import {PageNotFound} from './PageNotFount';
import NavBar from '../common/components/NavBar/NavBar.component';
import {Contact} from './Contact';
import {Col, Container, Row} from 'react-bootstrap';
import {Home} from './Home';
import Footer from '../common/components/NavBar/Footer/Footer.component';
import {Offer} from './Offer.page';
import {Prices} from './Prices.page';
import {Employees} from './Employees.page';

const Content = styled.div`
  padding-top: 70px;
  margin-top: 1rem;
`
const GlobalStyleOpenSans = createGlobalStyle`
  body{
    @import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
    font-family: 'Open Sans', sans-serif;
    text-align: justify;
  }
`

const App: React.FC = () => {
  return (
    <>
      <GlobalStyleOpenSans/>
      <NavBar />
      <Content className="justify-content-center">
        <Container>
          <Row>
            <Col>
              <Switch>
                <Route exact path="/" component={Home}></Route>
                <Route path="/offer" component={Offer}></Route>
                <Route path="/prices" component={Prices}></Route>
                <Route path="/employees" component={Employees}></Route>
                <Route path="/contact" component={Contact}></Route>
                <Route component={PageNotFound}></Route>
              </Switch>
              <Footer>Copyright © {new Date().getFullYear()} - Anident Studio Stomatologii - Wszelkie prawa zastrzeżone.</Footer>
            </Col>
          </Row>
        </Container>
      </Content>
    </>
  );
}

export default App;
