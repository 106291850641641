import * as React from 'react';
import {Accordion, Button, Table, Card} from 'react-bootstrap';
import COLORS from '../common/colors/colors';
import styled from 'styled-components';

const TableHeadingCustom = styled.thead`
  background-color: ${COLORS.superLightPink};
`

interface Row {
    values: string[]
}

interface TableData {
    title?: string
    heading?: Row
    rows: Row[]
}

const zacho: TableData = {
    title: 'Profilaktyka stomatologiczna',
    rows: [
        {values: ['Badanie stomatologiczne (jeżeli są wykonywane inne zabiegi)', 'Bezpłatnie']},
        {values: ['Badanie stomatologiczne (jeżeli nie są wykonywane inne zabiegi)', '100 zł']},
        {values: ['Higienizacja (piaskowanie+skaling)', 'od 350 zł']},
        {values: ['Lakierowanie zębów', '100 zł']},
        {values: ['Lakowanie zęba', '100 zł']},
        {values: ['RTG zębowe', '50 zł']},
        {values: ['Znieczulenie tradycyjne', '50 zł']},
        {values: ['Znieczulenie komputerowe', '70 zł']},
        {values: ['Wypełnienie w zębie stałym', '200 - 400 zł']},
        {values: ['Opatrunek leczniczy', '150 zł']},
        {values: ['Odbudowa zrębu zęba na ćwieku', 'od 400 zł']},
        {values: ['Leczenie kanałowe: 1 kanał', '500 - 600 zł']},
        {values: ['Leczenie kanałowe: 2 kanały', '600 - 800 zł']},
        {values: ['Leczenie kanałowe: powyżej 2 kanałów', '800 - 1000 zł']},
        {values: ['Wybielanie zębów nakładkowe', '1000 zł']},
        {values: ['Wybielanie zębów gabinetowe', '1500 zł']},
    ]
}

const prota: TableData = {
    title: 'Protetyka',
    rows: [
        {values: ['Proteza akrylowa', '2000 zł']},
        {values: ['Proteza elastyczna (Acron)', '2500 zł']},
        {values: ['Proteza szkieletowa', '2800 zł']},
        {values: ['Korona lana licowania ceramiką', '1200 zł']},
        {values: ['Zdjęcie korony', '100 zł']},
        {values: ['Korona tymczasowa', '100 zł']},
        {values: ['Most 1 punkt', '1000 zł']},
        {values: ['Wkład koronowo-korzeniowy lany', '500 zł']},
        {values: ['Dostawienie 1 zęba do protezy', '200 - 300 zł']},
        {values: ['Podścielenie/naprawa protezy', 'od 200 zł']}
    ]
}

const estetyczna: TableData = {
    title: 'Medycyna estetyczna',
    rows: [
        {values: ['Mezoterapia NCTF (rewitalizacja)', '300 zł / 1 amp']},
        {values: ['Neauvia Hydro Deluxe (nawilżenie)', '500 zł / 1 amp']},
        {values: ['Kolgen GUNA (wiotkość)', '300 zł / 1 amp']}
    ]
}

const medicalDocuments: TableData = {
    rows: [
        {values: ['1 strona wyciągu lub odpisu', '1 strona kserokopii', 'Wyciąg, odpis lub kopia na elektronicznym nośniku (za całość)']},
        {values: ['0,002 przeciętnego miesięcznego wynagrodzenia*', '0,0002 przeciętnego miesięcznego wynagrodzenia*', '0,002 przeciętnego miesięcznego wynagrodzenia*']},
        {values: ['8,54 zł', '0,85 zł', '8,54 zł']}
    ]
}

export const Prices = () => {

    const renderTable = (data: TableData) => <Table striped bordered hover>
        <TableHeadingCustom>
            {data.title && renderTitle(data.title)}
            {data.heading && renderHeading(data.heading)}
        </TableHeadingCustom>
        {renderRows(data.rows)}
    </Table>

    const renderTitle = (title: string) => <tr>
        <th colSpan={2}>{title}</th>
    </tr>

    const renderHeading = (header: Row) => <tr>
        {header.values.map(value => <th>{value}</th>)}
    </tr>

    const renderRows = (rows: Row[]) => rows.map(row => renderRow(row))

    const renderRow = (row: Row) => <tr>
        {row.values.map(value => <td>{value}</td>)}
    </tr>

    return <>
        <h1><b>Cennik</b></h1>
        <h3>Płatne świadczenia stomatologiczne udzielane poza umową z NFZ</h3>
        {renderTable(zacho)}
        {renderTable(prota)}
        {renderTable(estetyczna)}
        {/*<h3>Opłaty za udostępnianie dokumentacji medycznej</h3>*/}
        {/*Na podstawie ustawy o prawach pacjenta i Rzeczniku Praw Pacjenta z dnia 6 listopada 2008 r. (Dz. U. z 2009r., nr*/}
        {/*52, poz. 417 ze zm.) ustala się następujące opłaty za udostępnianie dokumentacji medycznej:*/}
        {/*{renderTable(medicalDocuments)}*/}
        {/*<p>*/}
        {/*    * przeciętnego wynagrodzenia w poprzednim kwartale, począwszy od pierwszego dnia następnego miesiąca po*/}
        {/*    ogłoszeniu przez Prezesa Głównego Urzędu Statystycznego w Dzienniku Urzędowym Rzeczypospolitej Polskiej*/}
        {/*    „Monitor Polski”*/}
        {/*</p>*/}
    </>
};
