import * as React from 'react';
import {Card, Row} from 'react-bootstrap';
import doctor from '../common/images/doctor400x300.svg';
import katarzyna from '../common/images/katarzyna_boguta2.jpg';

export const Employees = () => {

    const cardStyle: React.CSSProperties = {width: '20rem', display: 'inline-block', margin: '1em', textAlign: "left"};

    return <div className="d-flex flex-wrap flex-column align-items-center">
        <Row className="text-left">
            <h1><b>Pracownicy</b></h1>
        </Row>
        <Row className="justify-content-center">
            <Card style={cardStyle}>
                <Card.Img variant="top" src={doctor}/>
                <Card.Body>
                    <Card.Title>Anna Boguta</Card.Title>
                    <Card.Text>
                        Lekarz dentysta, specjalista I stopnia stomatologii ogólnej
                    </Card.Text>
                </Card.Body>
            </Card>

            <Card style={cardStyle}>
                <Card.Img variant="top" src={katarzyna}/>
                <Card.Body>
                    <Card.Title>Katarzyna Boguta</Card.Title>
                    <Card.Text>
                        Lekarz dentysta, stomatologia dziecięca, medycyna estetyczna
                    </Card.Text>
                </Card.Body>
            </Card>
        </Row>
        <Row className="justify-content-center">
            <Card style={cardStyle}>
                <Card.Img variant="top" src={doctor}/>
                <Card.Body>
                    <Card.Title>Małgorzata Kowalska-Góra</Card.Title>
                    <Card.Text>
                        Lekarz dentysta, specjalista I stopnia stomatologii ogólnej
                    </Card.Text>
                </Card.Body>
            </Card>

            <Card style={cardStyle}>
                <Card.Img variant="top" src={doctor}/>
                <Card.Body>
                    <Card.Title>Beata Wawrzyszuk</Card.Title>
                    <Card.Text>
                        <br/>
                        Asystentka stomatologiczna
                    </Card.Text>
                </Card.Body>
            </Card>
        </Row>
    </div>
};
