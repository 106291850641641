import * as React from 'react';
import styled from 'styled-components';

const Map = styled.iframe`
    width: 100%;
    height: 30rem;
`

export const Contact = () => {
    return <>
        <h1>
            <b>Kontakt</b>
        </h1>
        <h5>
            <p>Tel. Stacjonarny: <a href="tel:+48-81-58-56-309">(81) 58 - 56 - 309</a></p>
            <p>Tel. Komórkowy: <a href="tel:697-001-047">697 - 001 - 047</a></p>
            <p>Email: <a href="mailto:anidentstudio@gmail.com">anidentstudio@gmail.com</a></p>
            <p>Adres: <a href="https://goo.gl/maps/iW1MnVso7VmNpBKW8" target="blank">Trawniki 630, 21-044 (dawny Ośrodek Zdrowia)</a></p>
        </h5>
        <Map src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2503.2956426851692!2d22.99230031591494!3d51.13989904532709!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4723a8876908ac89%3A0x36d625c8fb6ab36a!2sTrawniki%20630%2C%2021-044%20Trawniki!5e0!3m2!1spl!2spl!4v1573939350294!5m2!1spl!2spl"></Map>
    </>
};
