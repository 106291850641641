import React, {useEffect, useRef, useState} from 'react';
import {
    Logo,
    MenuIcon,
    MobileMenu,
    MobileMenuContainer,
    MobileMenuLink,
    Nav,
    ShowOnDesktop,
    ShowOnMobile,
    StyledNavLink,
    TopBar
} from './NavBar.style';
import logo_top from '../../images/logo.png'
import {faBars} from '@fortawesome/free-solid-svg-icons'
import {NavLink} from "react-router-dom";

interface NavBarProps {
    className?: string;
}

const NavBar: React.FC<NavBarProps> = (props: NavBarProps) => {

  const [isVisible, setIsVisible] = useState(false);
  const mobileMenuContainerRef: any = useRef();

  useEffect(() => {

      const handleClickOutside = (event: any) => {
          if (mobileMenuContainerRef.current && !mobileMenuContainerRef.current.contains(event.target)) {
              setIsVisible(false);
          }
      };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    };
  }, []);

  const toggleMenu = () => setIsVisible(!isVisible);

  return (
    <TopBar>
      <Logo><NavLink to="/"><img src={logo_top} height={62} /></NavLink></Logo>
      <Nav>
        <ShowOnDesktop>
          <StyledNavLink to="/">Strona domowa</StyledNavLink>
          <StyledNavLink to="/offer">Oferta</StyledNavLink>
          <StyledNavLink to="/prices">Cennik</StyledNavLink>
          <StyledNavLink to="/employees">Pracownicy</StyledNavLink>
          <StyledNavLink to="/contact">Kontakt</StyledNavLink>
        </ShowOnDesktop>
        <ShowOnMobile>
          <MobileMenu onClick={toggleMenu} >
            <MenuIcon icon={faBars} />
          </MobileMenu>
            {isVisible &&
                <MobileMenuContainer ref={mobileMenuContainerRef}>
                    <MobileMenuLink to="/" onClick={() =>setIsVisible(false)}>Strona domowa</MobileMenuLink>
                    <MobileMenuLink to="/offer"onClick={() =>setIsVisible(false)}>Oferta</MobileMenuLink>
                    <MobileMenuLink to="/prices"onClick={() =>setIsVisible(false)}>Cennik</MobileMenuLink>
                    <MobileMenuLink to="/employees"onClick={() =>setIsVisible(false)}>Pracownicy</MobileMenuLink>
                    <MobileMenuLink to="/contact"onClick={() =>setIsVisible(false)}>Kontakt</MobileMenuLink>
                </MobileMenuContainer>
            }

        </ShowOnMobile>
      </Nav>
    </TopBar>
  );
}
  
export default NavBar;
