import * as React from 'react';
import { Card } from 'react-bootstrap';
import styled from 'styled-components';
import COLORS from '../common/colors/colors';

    const CardHeaderCustom = styled(Card.Header)`
        border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
        background-color: ${COLORS.superLightPink};
        padding: .75rem 1.25rem;
        margin-bottom: 0;
        border-bottom: 1px solid rgba(0,0,0,.125);
    `

export const Offer = () => {
    return <>
    <h1>
        <b>Oferta</b>
    </h1>
        <p>
        W poradni stomatologicznej osobom ubezpieczonym udzielane są bezpłatne świadczenia stomatologiczne z zakresu stomatologi ogólnej. Ponadto zajmujmemy się poniższymi dziedzinami i wykonujemy płatne zabiegi:
        </p>
        <Card >
        <CardHeaderCustom as="h5">Laserowe wybielanie:</CardHeaderCustom>
        <Card.Body>
            <Card.Text>
            Bardzo skuteczna metoda, w której czynnik wybielający aktywowany jest promieniowaniem laserowym. Zaletą tej metody jest uzyskanie zmiany koloru w krótkim czasie oraz pełna kontrola lekarza podczas zabiegu.
            </Card.Text>
        </Card.Body>
        </Card>
        <br/>

        <Card>
        <CardHeaderCustom as="h5">Radiowizjografia:</CardHeaderCustom>
        <Card.Body>
            <Card.Text>
            Zdjęcia RTG wykonywane są na miejscu za pomocą nowoczesnego cyfrowego aparatu RTG dzieki któremu, pacjent nie jest narażony na absorbcję tak dużej dawki promieniowania jak przy RTG konwencjonalnym.
            </Card.Text>
        </Card.Body>
        </Card>
        <br/>

        <Card>
        <CardHeaderCustom as="h5">Laseroterapia (chirurgia, biostymulacja):</CardHeaderCustom>
        <Card.Body>
            <Card.Text>
            Laser zastępuje skalpel dzięki czemu można przeprowadzać zabiegi chirurgicznes którym nie towarzyszy krwawienie. Laser biostymulacyjny redukuje czas gojenia, a także podnosi odporność pacjenta na ból na przykład przed zabiegiem wypełniania ubytków próchniczych.
            </Card.Text>
        </Card.Body>
        </Card>
        <br/>

        <Card>
        <CardHeaderCustom as="h5">Protetyka:</CardHeaderCustom>
        <Card.Body>
            <Card.Text>
            Przywracanie pierwotnych warunków zgryzowych po utracie zębów naturalnych. Stosujemy protezy stałe (nie dające się wyjąć z ust), ruchome (dające się wyjąć z ust) lub kombinowane (protezy ruchome połączone z uzupełnieniami stałymi za pomocą zasuw, zatrzasków lub koron). Stosujemy uzupełnienia ruchome akrylowe, protezy szkieletowe, protezy nylonowe, lub acetalowe oraz stałe korony i mosty (metalowe-lane, licowane porcelaną na podbudowie metalowej oraz pełnoceramiczne).
            </Card.Text>
        </Card.Body>
        </Card>
        <br/>

        <Card>
        <CardHeaderCustom as="h5">Stomatologia zachowawcza:</CardHeaderCustom>
        <Card.Body>
            <Card.Text>
            Zniszczona struktura zęba nie jest w stanie się zregenerować, jednakże leczenie może powstrzymać postępującą próchnicę, zachować zęba i zapobiec powikłaniom. Leczenie próchnicy zębów polega na usunięciu uszkodzonych tkanek, a w ich miejsce umieszcza się materiał przeznaczony do odbudowy.
            </Card.Text>
        </Card.Body>
        </Card>
        <br/>

        <Card>
        <CardHeaderCustom as="h5">Stomatologia estetyczna:</CardHeaderCustom>
        <Card.Body>
            <Card.Text>
            Zabiegi wykonywane w celu poprawy wyglądu zewnętrznego zębów. Wyróżnia się tutaj zabiegi takie jak wybielanie zębów, korekta kształtu oraz koloru zębów przy użyciu licówek ceramicznych lub kompozytowych, higienizacja (usuwanie kamienia, scaling, usuwanie osadu, piaskowanie).
            </Card.Text>
        </Card.Body>
        </Card>
        <br/>

        <Card>
        <CardHeaderCustom as="h5">Stomatologia dziecięca:</CardHeaderCustom>
        <Card.Body>
            <Card.Text>
            Tej dziedzinie poświęcamy najwięcej troski. Leczenie zębów w wieku rozwojowym wymaga szczególnego podejścia lekrza do pacjenta, tak aby zdobyte przez dziecko doświadczenie nie powodowało często spotykanej reakcji podczas dalszych wizyt, jaką jest strach. Oferujemy szeroką gamę zabiegów zapobiegawczych dla dzieci takich jak lakierowanie, lakowanie, fluoryzacja zębów oraz wiele innych. Dołożymy wszelkich starań aby Twoje dziecko wyszło z gabinetu z uśmiechem na buzi.
            </Card.Text>
        </Card.Body>
        </Card>
        <br/>

        <Card>
        <CardHeaderCustom as="h5">Endodoncja (leczenie kanałowe):</CardHeaderCustom>
        <Card.Body>
            <Card.Text>
            Jest elementem stomatologii zachowawczej. Polega na leczeniu chorób miazgi zęba oraz chorób tkanek okołowierzchołkowych. Leczenie kanałowe wykonuje się za pomocą bardzo precyzyjnych narzędzi penetrujących. Przed przystąpieniem do leczenia wymagane jest zdjęcie rentgenowskie w celu oceny stopnia choroby zęba. Dokładny pomiar głębokości kanału wykonywany jest za pomocą endometru. Na koniec leczenia kanały są wypełniane za pomocą uszczelniaczy.
            </Card.Text>
        </Card.Body>
        </Card>
        <br/>

    </>
};