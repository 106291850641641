import React from 'react';
import { StyledFooter } from './Footer.style';

interface FooterProps {
    children?: any;
}

const Footer: React.FC<FooterProps> = (props: FooterProps) => {
    return (
        <>
            <hr></hr>
            <StyledFooter>
                {props.children}
            </StyledFooter>
        </>
    );
  }

export default Footer;
