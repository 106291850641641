import * as React from 'react';
import {Jumbotron} from 'react-bootstrap';
import styled, {css} from 'styled-components';
import image1 from '../common/images/image1.jpg';
import COLORS from "../common/colors/colors";

const JumbotronCustom = styled(Jumbotron)`
  background-image: url(${image1});
  background-size: cover;
  color: white;
  background-position: center;
`

const fadeIn = css`
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
`;

const FadeIn = styled.div`
  ${fadeIn}
  animation: fadeIn .2s;
`;

const Wrapper = styled.div`
  .white-link {
    color: #ffffff;
    text-decoration: none; 
    :hover {
      color: ${COLORS.lightPink};
    }
  }
`;

export const Home = () => {
    return <Wrapper>
    <FadeIn>
    <JumbotronCustom>
        <h1>Zadzwoń i zapisz się na wizytę!</h1>
        <h5>
        <p style={{'background': "rgba(120, 120, 120, 0.60)", width: '15rem', borderRadius: '0.3rem', padding: '0.5rem'}}>
            <i className="bi bi-telephone"><a href="tel:+48815856309" className="white-link"> (81) 58 56 309</a></i><br/>
            <i className="bi bi-phone"> <a href="tel:+48697001047" className="white-link">697 001 047</a></i><br/>
            <i className="bi bi-pin-map"> <a href="https://goo.gl/maps/iW1MnVso7VmNpBKW8" target="_blank" className="white-link">Trawniki 630, 21-044</a></i><br/>
            <i className="bi bi-facebook"> <a href="https://www.facebook.com/anidentstudio" target="_blank" className="white-link">Facebook</a></i>
        </p>
        </h5>
    </JumbotronCustom>
    <h3>O działalności</h3>
    <p>
    Gabinet Anident Studio Stomatogii został założony przez lekarza dentystę Annę Bogutę, posiadającą 25-letnią praktykę zawodową. Ceniona za dokładność, zaangażowanie oraz jakość świadczonych usług stworzyła zespół profesjonalistów stawiających na czele takie same priorytety.

Uczestniczymy w licznych szkoleniach, dzięki czemu nie tylko zdobywamy kolejne lata doświadczeń, ale także stale rozwijamy i podnosimy poziom naszych kwalifikacji. Zadbaliśmy również o wszelkie nowinki techniczne związane z dziedziną stomatologii.

W naszym gabinecie zawsze można liczyć na pełen profesjonalizm oraz miłą atmosfrę podczas wizyt. Dbamy o samopoczucie oraz uśmiech na twarzy zarówno małych jak i dużych pacjentów, niezależnie od wykonywanego zabiegu.

Zapraszamy do skorzystania z naszych usług.

-  Zespół Anident.
    </p>
    </FadeIn>
    </Wrapper>
};
