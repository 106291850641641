import styled, {css} from 'styled-components'
import COLORS from '../../colors/colors';
import { NavLink } from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const TopBar = styled.div`
  background-color: ${COLORS.gray};
  display: block;
  padding: 0;
  height: 70px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.3);
`

export const Nav = styled.nav`
  list-style-type: none;
  float: right;
  margin: 0 2% 0 0;
  padding: 0;
  height: 100%;
`;

export const StyledNavLink = styled(NavLink)` &&{
  text-decoration: none;
  color: white;
  padding-left: 2rem;
  padding-right: 2rem;
  display: inline-block;
  text-align: center;
  height: 100%;
  line-height: 70px;
  -webkit-transition: background-color .2s;
  transition: background-color .2s;
  background-color: ${COLORS.gray};

  :hover {
    background-color: ${COLORS.darkGray};
    color: ${COLORS.lightPink};
  }

  :focus {
    z-index: 1;
    outline: 0 none;
    -webkit-transition: box-shadow .3s;
    transition: box-shadow .3s;
    box-shadow: inset 0 0 0 0.15em ${COLORS.lightPink};
  }

}
`

export const Logo = styled.a`
  margin-left: 3%;
  margin-top: .2rem;
  margin-bottom: .2rem;
  display: inline-block;
`

export const MenuIcon = styled(FontAwesomeIcon)`
  color: white;
  font-size: 2rem;
`

export const ShowOnMobile = styled.div`
  @media (min-width: 1024px) {
    display: none;
  }
`

export const ShowOnDesktop = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
`

export const MobileMenu = styled.div`
  color: white;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
  width: 100px;
  line-height: 70px;
  background-color: ${COLORS.gray};
  cursor: pointer;
  :hover {
    background-color: ${COLORS.darkGray};
  }
  height: 70px;
  padding-top: 5px;
`

export const fadeIn = css`
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
`

export const fadeOut = css`
  @keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }
`

export const MobileMenuContainer = styled.div`
  width: 14rem;
  position: absolute;
  top: 70px;
  right: 0;
  ${fadeIn}
  ${fadeOut}
  transition: visibility .2s;
`
export const MobileMenuLink = styled(StyledNavLink)`
  width: 100%;
`
